<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col>
            <Languages />
          </v-col>
          <v-col>
            <SelectedLanguage />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-textarea
            v-model="text"
            clearable
            outlined
            clear-icon="mdi-close-circle"
            label="Text"
            model-value="This is clearable text."
            height="100%"
            :counter="maxText"
        />
      </v-col>
      <transition name="slide-fade">
        <v-alert
            v-if="result"
            rounded
            fixed
            type="success"
        >
          Answer` {{ result }}
        </v-alert>
      </transition>
      <v-col cols="12">
        <v-btn
            block
            color="primary"
            @click="translate"
            :loading="btnLoader"
            :disabled="disabledBtn"
        >Translate</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { translateReduce } from '@/utils/translate'
import Languages from "@/components/translate/Languages";
import SelectedLanguage from "@/components/translate/SelectedLanguage";

import { useStore } from "@/store";
const store = useStore()
export default {
  name: 'HomePage',

  components: {
    SelectedLanguage,
    Languages,
  },

  data: () => ({
    text: '',
    result: '',
    maxText: 1000,
    btnLoader: false
  }),

  computed: {
    selectedLanguages() {
      return store.selectedLanguages
    },
    disabledBtn() {
      return !(this.selectedLanguages.length > 1 && this.text)
    }
  },

  methods: {
    async translate() {
      this.btnLoader = true
      this.text.substring(this.maxText)
      const langs = this.selectedLanguages.map(val => {
        return val.code
      })

      await translateReduce(this.text, langs).then((res) => {
        this.result = res
        this.btnLoader = false
      })
    },
  }
}
</script>
