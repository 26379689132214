import Vue from "vue";

const apiKey =
  "trnsl.1.1.20230407T133418Z.a97d79891ca75adc.dd505912490e83e832b1949a396946c381c1b9ad";
const apiUrl = "https://translate.yandex.net/api/v1.5/tr/translate";

const parseHtml = (html) => {
  return html.replace(/<[^>]+>/g, "");
};

const getTranslation = async (text, lang) => {
  const baseURI = `${apiUrl}?key=${apiKey}&text=${text}&lang=${lang}&format=html`;
  try {
    return await Vue.prototype.$http.get(baseURI);
  } catch (error) {
    throw new Error(error);
  }
};

export const languages = [
  {
    id: 1,
    name: 'Armenian',
    code: 'hy'
  },
  {
    id: 2,
    name: 'Russian',
    code: 'ru'
  },
  {
    id: 3,
    name: 'English',
    code: 'en'
  },
  {
    id: 4,
    name: 'Georgian',
    code: 'ka'
  },
  {
    id: 5,
    name: 'Albanian',
    code: 'sq'
  },
  {
    id: 6,
    name: 'Afrikaans',
    code: 'af'
  },
  {
    id: 7,
    name: 'Indonesian',
    code: 'id'
  },
  {
    id: 8,
    name: 'Italian',
    code: 'it'
  },
  {
    id: 9,
    name: 'Japanese',
    code: 'ja'
  },
  {
    id: 10,
    name: 'Kannada',
    code: 'kn'
  },
  {
    id: 11,
    name: 'Mongolian',
    code: 'mn'
  },
  {
    id: 12,
    name: 'Nepali',
    code: 'ne'
  },
  {
    id: 13,
    name: 'Polish',
    code: 'pl'
  },
  {
    id: 14,
    name: 'Serbian',
    code: 'sr'
  },
  {
    id: 15,
    name: 'Telugu',
    code: 'te'
  },
  {
    id: 16,
    name: 'Turkish',
    code: 'tr'
  },
  {
    id: 17,
    name: 'Ukrainian',
    code: 'uk'
  },
  {
    id: 18,
    name: 'Chinese',
    code: 'zh'
  },
  {
    id: 19,
    name: 'Hungarian',
    code: 'hu'
  },
  {
    id: 20,
    name: 'Finnish',
    code: 'fi'
  },
]

export const translateReduce = async (
  enteredText,
  langs = ["am", "en", "fr"]
) => {
  if (!langs.length) return enteredText;

  return langs.reduce((acc, b) => {
    return Promise.resolve(acc).then(() => {
      return getTranslation(enteredText, b)
        .then((res) => {
          return parseHtml(res.data);
        })
        .catch((error) => {
          throw new Error(error);
        });
    });
  });
};
