<template>
  <v-card
      min-height="50px"
      max-height="250px"
      height="100%"
      class="pa-3 overflow-y-auto"
  >
    <v-row class="mb-5" no-gutters align="center" justify="space-between">
      <h3 class="ma-0">Select languages</h3>
      <v-icon v-if="selectedLanguages.length" @click="clearAll">mdi-close</v-icon>
    </v-row>
    <draggable
        :list="selectedLanguages"
        class="list-group"
        ghost-class="ghost"
        @start="dragging = true"
        @end="dragging = false"
    >
      <transition-group
          class="row"
          :name="!dragging ? 'flip-list' : null"
          tag="div"
      >
        <v-chip
            v-for="(item, i) in selectedLanguages"
            :key="i"
            class="ma-2 subtitle-2"
            color="teal"
        >
          {{ item.name }}
          <v-icon class="ml-1" small @click="removeLanguage(i)">mdi-close</v-icon>
        </v-chip>
      </transition-group>
    </draggable>
    <p v-if="selectedLanguages.length === 1" class="ma-0 mb-3 mt-5 red--text">Selected at least 2 languages</p>
    <p v-else-if="selectedLanguages.length === 10" class="ma-0 mb-3 mt-5 red--text">Can select max 10 languages</p>
  </v-card>
</template>

<script>

import { useStore } from "@/store";
const store = useStore()
import draggable from "vuedraggable";

export default {
  name: "SelectedLanguage",

  data: () => ({
    enabled: true,
    dragging: false,
  }),

  components: {
    draggable,
  },

  computed: {
    languages() {
      return store.languages
    },
    selectedLanguages() {
      return store.selectedLanguages
    }
  },

  methods: {
    removeLanguage(key) {
      store.removeLanguage(key)
    },
    clearAll() {
      store.clearLanguage()
    },
  }
}
</script>

<style scoped>

</style>
