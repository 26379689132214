import { defineStore } from 'pinia'
import { languages } from '@/utils/translate'

export const useStore = defineStore('store', {
    state: () => {
        return {
            languages: languages,
            selectedLanguages: []
        }
    },
    actions: {
        selectedLanguage(item) {
            this.selectedLanguages.push((item))
        },
        removeLanguage(key) {
            this.selectedLanguages.splice(key, 1)
        },
        clearLanguage() {
            this.selectedLanguages = []
        },
    },
    getters: {}
})
