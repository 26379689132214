<template>
  <v-card class="pa-3">
    <h3 class="ma-0">Choose languages</h3>
    <v-list
        max-height="200px"
        class="overflow-y-auto"
    >
      <v-list-item
          v-for="item in languages"
          :key="item.id"
          @click="change(item)"
      >
        <template v-slot:default>
          <v-list-item-action start>
            {{ item.name }}
          </v-list-item-action>
        </template>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>

import { useStore } from "@/store";
const store = useStore()

export default {
  name: "Languages",

  computed: {
    languages() {
      return store.languages
    },
    selectedLanguages() {
      return store.selectedLanguages
    },
  },

  methods: {
    change(item) {
      if (this.selectedLanguages.length < 10) {
        store.selectedLanguage(item)
      }
    },
  }
}
</script>

<style scoped>

</style>
